var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-md-row flex-column justify-content-center flex-gap-2",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c("v-select", {
                          staticClass: "v-select-filter",
                          attrs: {
                            placeholder: "Select company..",
                            options: _vm.allCompanies,
                          },
                          on: { input: _vm.companyFilter },
                          model: {
                            value: _vm.selectedCompany,
                            callback: function ($$v) {
                              _vm.selectedCompany = $$v
                            },
                            expression: "selectedCompany",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("CInput", {
                      staticClass: "w-100 mb-0",
                      attrs: { type: "search", placeholder: "Search.." },
                      on: { input: _vm.searchFilter },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _vm.pages > 1
                      ? _c(
                          "div",
                          { staticClass: "d-none d-xl-block" },
                          [
                            _c("CPagination", {
                              attrs: {
                                align: "end",
                                dots: false,
                                pages: _vm.pages,
                                "active-page": _vm.activePage,
                              },
                              on: {
                                "update:activePage": function ($event) {
                                  _vm.activePage = $event
                                },
                                "update:active-page": function ($event) {
                                  _vm.activePage = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.selectedCompany
        ? _c("CAlert", { staticClass: "mb-4", attrs: { color: "warning" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-gap-2" },
              [
                _c("CIcon", {
                  attrs: { name: "cil-hand-point-up", size: "lg" },
                }),
                _c("h5", [_vm._v("Please select a company..")]),
              ],
              1
            ),
          ])
        : [
            _vm.mounting
              ? _c(
                  "div",
                  { staticClass: "mb-4" },
                  [_c("CSpinner", { attrs: { color: "primary" } })],
                  1
                )
              : _c("CDataTable", {
                  staticClass: "table-company-users",
                  attrs: {
                    striped: "",
                    hover: "",
                    items: _vm.loadedItems,
                    fields: _vm.fields,
                    sorter: { external: true, resetable: false },
                    itemsPerPageSelect: {
                      external: true,
                      values: [5, 15, 25, 50, 100, 250, 500],
                    },
                    "items-per-page": _vm.itemsPerPage,
                    loading: _vm.loading,
                    noItemsView: {
                      noResults: "No filtering results are available!",
                      noItems: "No users found!",
                    },
                    "clickable-rows": "",
                  },
                  on: {
                    "update:itemsPerPage": function ($event) {
                      _vm.itemsPerPage = $event
                    },
                    "update:items-per-page": function ($event) {
                      _vm.itemsPerPage = $event
                    },
                    "row-clicked": _vm.rowClicked,
                    "update:sorter-value": _vm.sorterValue,
                    "pagination-change": _vm.paginationChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "role",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            _c("strong", [_vm._v(_vm._s(item?.company_role))]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "id",
                      fn: function ({ item }) {
                        return [
                          _c("td", [_vm._v(" #" + _vm._s(item?.id) + " ")]),
                        ]
                      },
                    },
                    {
                      key: "image_icon",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "CLink",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.imageClicked(item)
                                    },
                                  },
                                },
                                [
                                  _c("CImg", {
                                    staticClass: "c-avatar-img",
                                    staticStyle: {
                                      width: "36px",
                                      height: "36px",
                                    },
                                    attrs: {
                                      src: item.image_icon || item.gravatar,
                                      placeholderColor: "lightgray",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "name",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            _vm._v(" " + _vm._s(item.first_name) + " "),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "contact",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            _c(
                              "div",
                              [
                                _c("CIcon", {
                                  staticClass: "mr-1",
                                  attrs: { name: "cil-at" },
                                }),
                                _vm._v(_vm._s(item.email)),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CIcon", {
                                  staticClass: "mr-1",
                                  attrs: { name: "cil-phone" },
                                }),
                                _vm._v(_vm._s(item.mobile)),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "menutepay_verified_at",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            item.menutepay_verified_at
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      new Date(
                                        item.menutepay_verified_at
                                      ).toLocaleString()
                                    )
                                  ),
                                ])
                              : _c(
                                  "span",
                                  [
                                    _c(
                                      "CLink",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.resendActivationEmail(
                                              item.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Resend Activation Email")]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "email_verified_at",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            item.email_verified_at
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      new Date(
                                        item.email_verified_at
                                      ).toLocaleString()
                                    )
                                  ),
                                ])
                              : _c("span", [_vm._v("Not verified")]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
          ],
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }